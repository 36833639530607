import {AfterViewInit, Input,ChangeDetectorRef, Component, OnChanges, OnInit, ViewChild, ElementRef, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title, DomSanitizer} from "@angular/platform-browser";
import {TriallinesResourceService} from "./shared/triallines-resoruce.service";
import {Trialline} from "../shared/trialline.model";
import {ActivatedRoute, Router} from "@angular/router";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {AuthService} from "../../auth/shared/auth.service";
import {User} from "../../auth/shared/user.model";
import {permissions} from "../../../../.env";
import {MattersComponent} from '../profile/clio/matters/matters.component';
import {ArchivedTriallinesComponent} from './archived-triallines/archived-triallines.component';
import {ProfileResourceService} from "../profile/shared/profile-resoruce.service";
//import {debugOutputAstAsTypeScript} from '@angular/compiler';
import {SharedService} from 'app/shared/shared.service';
import { log } from 'console';
import { CookieService } from 'ngx-cookie';
import { productFruits } from 'product-fruits';


declare var jQuery:any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './triallines.component.html',
  styleUrls: ['./triallines.component.css']
})
export class TriallinesComponent implements OnInit
{
  public activeTab: string;
  triallines: Trialline[] = [];
  forbidden: boolean = false;

  showCreateForm: boolean = false;
  
  subscriptionFormId = '#subscription-modal';
  firstTimeModalId = '#from-old-first-time-modal';

  permissions = permissions; 

  user: User;

  firstTime: boolean = true;
  public searchKey:string="";
  searchMat:string="";

  //Pagination
  page: number = 1;
  order: string = 'created_at';
  orderValue: string='';
  perPage:number=25;
  total: number;
  reverse: boolean = true;
  triallineCall: boolean = true;
  newTrialUser: boolean = false;

  @ViewChild('myInput', { static: true })
myInputVariable: ElementRef;
@ViewChild(MattersComponent, { static: true }) matterObj: MattersComponent;
@ViewChild(ArchivedTriallinesComponent, { static: true }) archivedObj: ArchivedTriallinesComponent;
  columnname: any;
  
  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute, public router: Router,
    public modal: Modal,
    private _changeDetectorRef: ChangeDetectorRef,
    private auth: AuthService,
    private triallinesResourceService: TriallinesResourceService,
    private _sanitizer: DomSanitizer,
    protected sharedService: SharedService, 
    private profileResourceService: ProfileResourceService,
    public cookieService: CookieService, 
  )
  {
    title.setTitle('Trialline | Dashboard')
    // console.log(1)
    auth.user().subscribe
    ( (user: User) =>
    {
      console.log("user", user)
      this.user = user;
      productFruits.init(
        'M70WYbpzENxLzGpH', 
        'en', 
        { 
          username: this.user.email, 
          email: this.user.email,
          firstname:(this.user.first_name) ? this.user.first_name: 'NA',
          lastname:(this.user.last_name) ? this.user.last_name: 'NA'
        }
      );

      // code comment for guest user
      // if(user.subscription_plan == null) {
      //   this.forbidden = true;
      //   if (!this._changeDetectorRef['destroyed']) {
      //     this._changeDetectorRef.detectChanges();
      //   }
      // }
        if (this.user && this.forbidden && this.user.subscription_plan)
        {
          console.log("this.user",this.user,this.forbidden,this.user.subscription_plan );
          if(this.triallineCall) {
              this.reloadTriallines(null);
            if (!this._changeDetectorRef['destroyed']) {
              this._changeDetectorRef.detectChanges();
            }
          }
        }
        if (this.user && this.firstTime) {

          this.firstTime = false;

         if (this.user.user_type != 3) {
            if (this.user.first_time  && this.user.user_type != 5) {
              jQuery(this.firstTimeModalId).modal('show');
              this.markStartBanner();

             } else if (! this.user.subscription_plan && this.user.user_type != 3 && ! this.user.trial_days_count && ! this.user.subscription_past_due)
             {

              //  jQuery(this.subscriptionFormId).modal('show');
            } else if (this.user.subscription_past_due && !this.user.trial_days_count && this.user.trial_end_banner==1) {

              jQuery('#trial-end-banner').modal('show');
              this.markTrialEndBanner();
            } else if (this.user.subscription_past_due &&  this.user.trial_end_banner==2) {
              jQuery('#subscription-trial-end-banner').modal('show')
            }
          }
        }
      },
      (error: any) => {
        auth.logout();
        this.router.navigateByUrl('/');
      }
    );

    auth.userChangeEvent.subscribe(user => this.user = user);
  }

  triallineCustomDateFormat(trialline_date_format, currentDateFormat)
  {
    //console.log('main trialline_date_format', trialline_date_format);
    if(trialline_date_format) {
      const fullDate = currentDateFormat.split(' ');
      const dateBlocks = fullDate[0].split('-');
      
      switch (trialline_date_format) {
          case 'MDY':              
              return dateBlocks[1]+'/'+dateBlocks[2]+'/'+dateBlocks[0]+' '+fullDate[1];
              break;
          case 'YMD':
              return dateBlocks[0]+'/'+dateBlocks[1]+'/'+dateBlocks[2]+' '+fullDate[1];
              break;
          case 'DMY':
              return dateBlocks[2]+'/'+dateBlocks[1]+'/'+dateBlocks[0]+' '+fullDate[1];
              break;
          default:
              return currentDateFormat;
              break;
      }

    } else {
      return currentDateFormat;
    } 

  }

  update() {
console.log("update");

    this.triallinesResourceService.getTriallinesByPage({page: this.page, sort: {field: this.order,
      order: this.orderValue},name:null, limit: this.perPage})
        .subscribe((response) => {
          this.forbidden = false;
          this.total = response['total_count'];
          this.triallines = response['triallines'];
          this.page = response['page'];
      this._changeDetectorRef.detectChanges();
    });

    // this.triallinesResourceService.getTriallines().toPromise()
    //   .then((triallines: Trialline[]) => {

    //     this.forbidden = false;
    //     this.triallines = triallines;
    //     this._changeDetectorRef.detectChanges();
    //   })
    //   .catch((err: Response) => {
    //     if(err.status == 403) {

    //       this.forbidden = true;
    //     }
    //   })
    this._changeDetectorRef.detectChanges();
  }

  markStartBanner() {
      this.triallinesResourceService.switchFirstTime(false).toPromise()
        .then((r: any) => {}).catch(e => { console.log(e); })
  }

  markTrialEndBanner() {
    this.triallinesResourceService.switchTrialEnd(false).toPromise()
      .then((r: any) => {}).catch(e => { console.log(e); })
  }

  openSubscriptionFormModal(ev)
  {
     if (this.user.user_type != 3)
     {
       jQuery(this.subscriptionFormId).modal('show');
     }
  }

  reloadTriallines(ev)
  {
    this.triallineCall = false;
    this.auth.emit();
    console.log("reloadTriallines");
    this.triallinesResourceService.getTriallinesByPage({page: this.page, sort: {field: this.order,
      order: this.orderValue},name:null, limit: this.perPage})
        .subscribe((response) => {
          this.forbidden = false;
          this.triallines = response['triallines'];
          this.total = response['total_count'];
          this.page = response['page'];
      this._changeDetectorRef.detectChanges();
    },
    (error: any) => {
      this.triallineCall = false;
      this.toasts.error(error.error.meta.status);

    });
  }
//pagination
pageChanged(data: any){
  console.log("pageChanged");
  this.triallinesResourceService.getTriallinesByPage({page: data, sort: {field: this.order,
    order: this.orderValue},name:null, limit: this.perPage})
      .subscribe((response) => {
    this.forbidden = false;
    this.triallines = response['triallines'];
    this.total = response['total_count'];
    this.page = response['page'];
  });
}

changePages(data: any){
  console.log("changePages");
  this.perPage = data.target.value;
  this.triallinesResourceService.getTriallinesByPage({page: this.page, sort: {field: this.order,
      order: this.orderValue},name:null, limit: this.perPage})
    .subscribe((response) => {
      this.triallines = response['triallines'];
    this.total = response['total_count'];
    this.page = response['page'];
    });
}
onSearchChange(searchValue): void
{
  console.log("onSearchChange");
 setTimeout(()=>{
  this.triallinesResourceService.getTriallinesByPage({page: 1, sort: {field: this.order,
    order: this.orderValue},name:this.searchKey, limit: this.perPage})
      .subscribe((response) => {
    this.forbidden = false;
    this.triallines = response['triallines'];
    this.total = response['total_count'];
    this.page = response['page'];
  });
},1500);
}


  ngOnInit()
  { 
    this.columnname='name';
    this.route.data.subscribe((data: any) => {
      let triallines: any = data.triallines;
        console.log("userr herer", this.user)
/*       console.log(triallines.status); */
      if (triallines.status == 403)
      {
        this.forbidden = true;
      } else {
        this.triallines = triallines.triallines;
        this.total=data.triallines.total_count;
      }
    });
    this.activeTab = 'dashboard';
    console.log('this.triallines',this.triallines);
   
    this.profileResourceService.freeTrialUser().toPromise()
      .then((res: any) => {
        if(res) {
          this.newTrialUser = true;
          this.cookieService.put('showFreeTrialPopup', 'downtoup');
        } else {
          this.newTrialUser = false;
          this.cookieService.remove('showFreeTrialPopup');
        }
      })
      .catch((error) => {
        console.log('get free Trial User Error: ', error);        
    });

  }

  showCreateBlockModal() {
    const dialogRef = this.modal.alert()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Please Add Payment Method')
      .body('We are sorry, but you have not added a primary payment option. To create an additional TrialLine for' +
          ' $9.99/month, please select \'OK\' and then add your credit card information')
      .open()
      dialogRef.result
      .catch((err: any) => {
        console.log('ERROR: ' + err);
        this.modelClose()
      })
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.router.navigateByUrl('/profile/billing')
        }
         })
    this.modelAction();
  }

  newTrialline() {
    // if(this.user.isFree == 0) {
    //   if ((!this.user.card_last_four && !this.user.corporation_id) && this.triallines.length > 1) {
    //     return this.redirectToBilling();
    //   }
    // }
    this.openNewTriallineModal();
  }

  openNewTriallineModal() {
    this.showCreateForm = true;
    this._changeDetectorRef.detectChanges();
    jQuery('#newTrialLine').modal('show');
  }

  addTrialline(trialline: Trialline) {
    this.triallines.push(trialline);
    this.showCreateForm = false;
  }

  confirmedArchiveTrialline(trialline: Trialline) {
    this.triallinesResourceService.archiveTrialline(trialline.id).toPromise()
      .then((trialline: Trialline) => {

        let index = this.triallines.indexOf(this.triallines.find(e => e.id == trialline.id));
        //this.triallines[index] = trialline;
        
        this.triallines.splice(index, 1);

        this.archivedObj.ngOnInit();
        this.toasts.success('The TrialLine was archivated');
      })
      .catch((error) => {
       let mess = 'We are sorry, but you have not added a primary payment option. Please add your credit card information';
        if (error.status == 403) {
          let errorData = error.error;

          if (errorData.error.message) {
            console.log(errorData.error.message);
            this.toasts.error(mess);
          }
        } else {
          console.log(error);
          this.toasts.error(mess);
        }
      });
  }

  confirmedCopyTrialline(trialline: Trialline) {
    this.triallinesResourceService.copyTrialline(trialline.id).toPromise()
      .then((trialline: Trialline) => {

        let index = this.triallines.indexOf(this.triallines.find(e => e.id == trialline.id));
        this.triallines[index] = trialline;
        this.addTrialline(trialline)
        this.toasts.success('The TrialLine was copied.');
      })
      .catch((error) => {
        if (error.status == 403) {
          let errorData = error.error;
          if (errorData.error.message) { this.toasts.error(errorData.error.message); }
        } else {
          this.toasts.error(error);
        }
      });
  }

  confirmedOpenTrialline(trialline: Trialline) {

    this.triallinesResourceService.openTrialline(trialline.id).toPromise()
      .then((trialline: Trialline) => {

        let index = this.triallines.indexOf(this.triallines.find(e => e.id == trialline.id));
        this.triallines[index] = trialline;

        this.toasts.success('The TrialLine was opened');
      })
      .catch((error) => {

        if (error.status == 403 && error.error.error.message) {

          this.toasts.error(error.error.error.message.error.message)
        } else {

          this.toasts.error(error);
        }
      });
  }
  
  confirmedDeleteTrialline(trialline: Trialline) { // it would be archived with deletion tag and moved to archive table
    this.triallinesResourceService.archiveDeletedTrialline(trialline.id, 'fordelete').toPromise()
      .then((trialline: Trialline) => {

        let index = this.triallines.indexOf(this.triallines.find(e => e.id == trialline.id));
        //this.triallines[index] = trialline;
        this.triallines.splice(index, 1);

        this.archivedObj.ngOnInit();
        this.toasts.success('The TrialLine has been archivated');
      })
      .catch((error) => {
       let mess = 'We are sorry, but you have not added a primary payment option. Please add your credit card information';
        if (error.status == 403) {
          let errorData = error.error;

          if (errorData.error.message) {
            console.log(errorData.error.message);
            this.toasts.error(mess);
          }
        } else {
          console.log(error);
          this.toasts.error(mess);
        }
      });
  }

  // confirmedDeleteTrialline(trialline: Trialline) {
  //   this.triallinesResourceService.deleteTrialline(trialline.id).toPromise()
  //     .then((r: any) => {
  //       if (trialline.parent_trialline_id) {
  //         let parentIndex = this.triallines.indexOf(this.triallines.find(e => e.id == trialline.parent_trialline_id));
  //         let index = this.triallines[parentIndex].childs
  //           .indexOf(this.triallines[parentIndex].childs.find(e => e.id == trialline.id));
  //         this.triallines[parentIndex].childs.splice(index, 1);
  //       } else {
  //         let index = this.triallines.indexOf(this.triallines.find(e => e.id == trialline.id));
  //         this.triallines.splice(index, 1);
  //       }
  //       this.toasts.success('Successfully deleted TrialLine')
  //     })
  //     .catch((error) => {
  //       let errorData = error.error;
  //       if (errorData.error) {
  //         this.toasts.error(errorData.error);
  //       } else {
  //         this.toasts.error(errorData);
  //       }
  //     });
  // }

  confirmedRepayTrialline(trialline: Trialline) {
    if (! this.user.card_last_four && !this.user.corporation_id) return this.redirectToBilling();
    this.triallinesResourceService.repayTrialline(trialline.id).toPromise()
      .then((trialline: Trialline) => {

        let index = this.triallines.indexOf(this.triallines.find(e => e.id == trialline.id));
        this.triallines[index] = trialline;

        this.toasts.success('Thank you! Debt was replay.')
      })
      .catch((error) => {

        if (error.status == 403 || error.status == 400) {
          let errorData = error.error;

          if (errorData.error.message) { this.toasts.error(errorData.error.message); }
        } else {

          this.toasts.error(error);
        }
      });
  }

  useAsPrimary(trialline: Trialline) {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Please confirm')
      .body('Are you sure, you want to use TrialLine: ' + trialline.name + ' as primary version?')
      .open()
      dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)
      })
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.confirmedUseAsPrimary(trialline)
        }
      });
    this.modelAction();
  }

  confirmedUseAsPrimary(trialline: Trialline) {

    this.triallinesResourceService.useAsPrimary(trialline.id).toPromise()
      .then((triallineNew: Trialline) => {

        let index = this.triallines.indexOf(this.triallines.find(e => e.id == trialline.parent_trialline_id));

        this.triallines[index] = triallineNew;

        this.toasts.success('Successfully setted up');
      })
      .catch((error) => {

        let errorData = error.error;
        if (errorData.error.message) {

          this.toasts.error(errorData.error.message);
        }
        else {

          this.toasts.error(errorData);
        }
      });
  }

  redirectToBilling() {
    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Billing info missed')
      .body('We’re sorry, but during your free trial period you cannot have more than two free TrialLines.' +
          ' To add an additional TrialLine, please add a credit card to your billing information and activate' +
          ' your ongoing paid subscription.')
      .open()
      dialogRef.result
      .catch((err: any) => {
        this.modelClose()
        console.log('ERROR: ' + err)
      })
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.router.navigateByUrl('/profile/billing')
        }
      });
    this.modelAction();
  }

  deleteTrialline(trialline: Trialline) {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Please confirm')
      //.body('Warning! If you delete this TrialLine you will not be able to retrieve it. Are you sure you want to' +' delete this TrialLine?')
      .body('This TrialLine will be moved to your archive and then permanently deleted after 30 days. To permanently delete it now, do so from the archive located in your dashboard.')
      .open()
      dialogRef.result
      .catch((err: any) => {
        console.log('ERROR: ' + err)
        this.modelClose()
      })
      .then((dialog: any) => {
        if(dialog){
          this.confirmedDeleteTrialline(trialline)
        }
        this.modelClose()
      });
    this.modelAction();
  }

  archiveTrialline(trialline: Trialline) {

    let title = 'Archive ' + trialline.name + ' TrialLine.'
    // let body = 'You can archive this TrialLine and related documents for $0.99 cents per month. While archived, you and your' +
    //   ' team will not be able to edit this TrialLine. You may reactivate this TrialLine at any time and per the' +
    //   ' “Reactivation Terms” found in your “Terms of Use” within the “My Profile” section of your Dashboard. Do' +
    //   ' you agree to the terms and wish to proceed with archive?'

    let body = 'You can archive this TrialLine and related documents. While archived, you and your' +
      ' team will not be able to edit this TrialLine. Do' +
      ' you agree to proceed with archive?'

    if((this.user.subscription_plan=='month')||(this.user.subscription_plan=='annual')) {
       body = 'You can archive this TrialLine and related documents. While archived, you and your' +
       ' team will not be able to edit this TrialLine. Do' +
       ' you agree to proceed with archive?'
    }

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title(title)
      .body(body)
      .open()
    dialogRef.result
      .catch((err: any) => {
        console.log('ERROR: ' + err)
        this.modelClose()
      })
      .then((dialog: any) => {
        this.modelClose()

        if(dialog){
          this.confirmedArchiveTrialline(trialline)
        }
      });
    this.modelAction();
 }

  copyTrialline(trialline: Trialline) {
    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title( 'Copy ' + trialline.name + ' TrialLine.')
      .body('You are about to duplicate this TrialLine, do you wish to continue?')
      .okBtn('Yes')
      .cancelBtn('No')
      .open()
    dialogRef.result
      .catch((err: any) => {
        console.log('ERROR: ' + err)
        this.modelClose()
      })
      .then((dialog: any) => {
        if(dialog){
          this.modelClose()
          this.confirmedCopyTrialline(trialline)
        }
      });
    this.modelAction();
 }

  openTrialline(trialline: Trialline)
  {
    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('You are about to reactivate the TrialLine ' + trialline.name + '.')
      .body('When you reactivate this TrialLine' +
        ' you will be responsible for the charges and pricing detailed in the “Reactivation Terms” found in your' +
        ' “Terms of Use” within the “My Profile” section of your Dashboard. Do you agree to the terms and wish' +
        ' to proceed with the reactivation?')
      .open()
      dialogRef.result
      .catch((err: any) => {
        console.log('ERROR: ' + err)
        this.modelClose()
      })
      .then((dialog: any) => {
        if(dialog){
          this.modelClose()
          this.confirmedOpenTrialline(trialline)
        }
      })
    this.modelAction();
  }

  repayTrialline(trialline: Trialline) {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .title('Expiration Notice')
      .body('Thank you for using TrialLine. Your 30-day free trial has now expired. To continue to use this' +
          ' TrialLine, please click “OK” and enter your credit card information. You will be billed $9.99/month.')
      .open()
      dialogRef.result
      .catch((err: any) => {
        console.log('ERROR: ' + err)
        this.modelClose()
      })
      .then((dialog: any) => {
        this.modelClose()
        return dialog.result
      })
      .then((result: any) => { this.confirmedRepayTrialline(trialline) })
      .catch((err: any) => { /** Nothing to do */ });
    this.modelAction();
  }


  reorder(column: string) {

    this.columnname = column
    if (column == this.order) {
      this.orderValue = this.orderValue === 'DESC' ? 'ASC' : 'DESC';
      this.reverse = ! this.reverse;
      console.log("reorder if");
      this.triallinesResourceService.getTriallinesByPage({page: this.page, sort: {field: column,
        order: this.orderValue},name:this.searchKey, limit: this.perPage})
          .subscribe((response) => {
        this.forbidden = false;
        this.triallines = response['triallines'];
        this.total = response['total_count'];
        this.page = response['page'];
      });
    } else
    {
      this.orderValue = 'ASC';
      this.order = column;
      this.reverse = true;
      console.log("reorder else");
      this.triallinesResourceService.getTriallinesByPage({page: this.page, sort: {field: column,
        order: this.orderValue},name:this.searchKey, limit: this.perPage})
          .subscribe((response) => {
        this.forbidden = false;
        this.triallines = response['triallines'];
        this.total = response['total_count'];
        this.page = response['page'];
      });
    }

  }
  getSortIcon(column: string) {

    return this._sanitizer.bypassSecurityTrustHtml('<i class="fa fa-sort' +
      (column == this.order ? '-' + (this.reverse ? 'asc' : 'desc') : '')  + '"' +
      ' aria-hidden="true"></i>');
  }
  onMatSearch(data:any){
    setTimeout(()=>{
      this.matterObj.onMatterSearch(this.searchMat)
    },1500);
  }

  triallinetest(id) {
    this.sharedService.status=false;
    if(this.sharedService.triallineidcheck!=id) {
      this.sharedService.checkid=0;
      this.sharedService.tagsid=[];
    }
  }

  modelAction() {
    $('bs-modal-container').addClass('show-midal-element');
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }

}
