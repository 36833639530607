
import {catchError, map} from 'rxjs/operators';
import {Injectable} from "@angular/core";

import {Observable, throwError} from 'rxjs';


import {RestService} from "../../../rest/shared/rest.service";
import {UserProfile} from "./user.model";
import {Payment} from "../../shared/payment.model";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class ProfileResourceService
{
  constructor(public http: RestService) {}

  freeTrialUser(): Observable<any>
  {
    
    return this.http.get('free/trial/user').pipe(
      map((response: any) => {
        console.log('freetiral user result:',response)
        return response;
      }));

  }

  getUser(): Observable<any>
  {

    return this.http.get('profile?with=tlines,events').pipe(
      map((response: any) => {
        let body = response.body;

        return body.user;
      }));

  }

  getCards(): Observable<any>
  {
    return this.http.get('billing').pipe(
      map((response: any) => {
        let body = response.body;

        return body.cards;
      }));
  }

  deleteCard(token): Observable<any>
  {
    return this.http.delete('billing/card?token=' + token).pipe(map(
      r => { return r.body; }
    ));
  }

  makePrimary(token): Observable<any>
  {
    return this.http.post('billing/make-primary', {token: token}).pipe(map(
      r => { return r.body; }
    ));
  }

  getCounts(): Observable<any>
  {
    return this.http.get('profile/counts').pipe(map(
      r => { return r.body; }
    ));
  }

  getBilling(trialline_id?: number): Observable<any>
  {
    let url = 'profile/billing' + (trialline_id ? '?trialline_id=' + trialline_id : '');

    return this.http.get(url).pipe(
      map((response: any) => {
        let body = response.body;

        return body.payments;
      }));

  }

  updateProfile(data: any): Observable<any>
  {
    return this.http.post('profile', data).pipe(
      map((response: any) => {
        let body = response.body;

        return body.user;
      }));
  }

  updatePassword(data: any): Observable<any>
  {
    return this.http.post('profile/password', data).pipe(map(
      r => { return r.body; }
    ));
  }

  updateClio(data: any): Observable<any>
  {

    return this.http.post('profile/clio', data).pipe(map(
      r => { return r.body; }
    ));
  }

  getClioDocuments(id: number): Observable<any>
  {

    return this.http.post('profile/clio/files/' + id, '').pipe(map(
      r => { return r.body; }
    ));
  }

  uploadFiles(data): Observable<any>
  {
    return this.http.post('profile/clio/files_upload', {files: data}).pipe(map(
      r => { return r.body; }
    ));
  }


  getClioMatters(id: number,data:any): Observable<any>
  {
    return this.http.post('profile/clio/matters/' + id, data).pipe(map(
      r => { return r.body; }
    ));

  }

  createTriallineFromMatter(id: number, user_id: number): Observable<any>
  {
    return this.http.post('profile/clio/matter/' + id, {user_id : user_id}).pipe(map(
      r => { return r.body; }
    ));

  }

  updateTriallineMatter(tl_id: number, user_id: number): Observable<any>
  {
    return this.http.post('profile/clio/trialline/' + tl_id, {user_id : user_id}).pipe(map(this.extractFeeds),
      catchError(this.handleError),);
  }

  addCard(data: any): Observable<any>
  {
    return this.http.post('billing/card', data).pipe(map(this.extractFeeds),
      catchError(this.handleError),);
  };

  createSubscription(planId)
  {
    return this.http.post('billing/subscription', {plan: planId}).pipe(map(r => this.extractFeeds(r)),
      catchError(this.handleError),);
  }

  cancelSubscription()
  {
    return this.http.delete('billing/subscription').pipe(map(
      r => r.body
    ));
  }

  renewSubscription()
  {

    return this.http.post('billing/renew', {}).pipe(map((response: any) => {
      let body = response.body;

      return body.user;
    }));
  }

  disableFirstTime()
  {

    return this.http.post('disable_first', {}, {}, false).pipe(map(
      r => r.body
    ));
  }



  getTransactions(triallineIdData: any = 0) {

    return this.http.get('billing/transactions/'+ triallineIdData, {}).pipe(map((response: any) => {
      let body = response.body;

      return body.transactions;
    }));
  }

  getFeedContent(): Observable<any> {
    return this.http.get('profile/Feed').pipe(map((response: any) => {
      let body = response.body;

      return body.feed;
    }));
  }

  private extractFeeds(res) {
    return res || { };
  }

  private handleError (errorObg: any) {
    let error = errorObg.error
    if (error.status === 404) return throwError( error )

    return throwError( error )
    let errort = error.error;
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message

    let errMsg = (errort.message) ? errort.message :
      errort.meta.status ? `${errort.error.status} - ${errort.error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead

    return throwError( errMsg );
  }
}

