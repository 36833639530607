<div class="page-header">
  <div class="page-header-top">
    <div class="container">
      <!-- BEGIN LOGO -->
      <div class="page-logo">
        <a href="/">
          <img src="assets/images/logo.png" width="150px" alt="" class="logo-trialline pull-left">
        </a>
      </div>
      <!-- END LOGO -->

       <img src="assets/squares.gif" class="img spinners" alt="spinner" *ngIf="showSpinners">
      <!-- BEGIN TOP NAVIGATION MENU -->
      <div class="top-menu" *ngIf="user">
        <div class="pull-left" style="margin-top: 5px" *ngIf="user?.trial_days_count && user?.trial_days_count > 0 && user?.isFree==0">Trial ends after: {{ user?.trial_days_count }} days</div>
<!--        <div class="pull-left" style="margin-top: 5px" *ngIf="user?.subscription_past_due && user?.isFree==0">-->
<!--          Your subscription is not active. <button [disabled]="renewing" [routerLink]="['/profile/subscription']" class="btn btn-sm btn-info">Renew</button>-->
<!--        </div>-->

        <div class="pull-left" style="margin-top: 5px" *ngIf="user?.isFree==1">
          You are a free user. </div>

        <div [attr.data-letters]="user?.first_name.charAt(0).toUpperCase() + '' + user?.last_name.charAt(0).toUpperCase()" class="headerlogin-user-avatar"></div>
        <div class="headerlogin-user-name"> {{ user?.first_name }} {{ user?.last_name }} </div>
        <a (click)="logout()" class="headerlogin-logout"><i class="fa fa-sign-out"></i></a>
      </div>
      <!-- END TOP NAVIGATION MENU --> 
    </div>
  </div>
  <!-- BEGIN HEADER MENU -->
  <div class="page-header-menu" style="display: block;">
    <div class="container">
      <!-- BEGIN MEGA MENU -->
      <!-- DOC: Apply "hor-menu-light" class after the "hor-menu" class below to have a horizontal menu with white background -->
      <!-- DOC: Remove data-hover="dropdown" and data-close-others="true" attributes below to disable the dropdown opening on mouse hover -->
      <div class="hor-menu">
        <ul class="nav navbar-nav">
          <li class="menu-dropdown classic-menu-dropdown" [ngClass]="{active: isActiveRoute('dashboard')}">
            <a [routerLink]="['/dashboard']"> My Dashboard
              <span class="arrow"></span>
            </a>
          </li>
          <li *ngIf="user && (user?.user_type != 5 && user?.team_access_level != 1) && (user?.subscription_plan && user?.subscription_plan != 'basic')" class="menu-dropdown classic-menu-dropdown" [ngClass]="{active: isActiveRoute('team')}">
            <a [routerLink]="['/team']"> Team
              <span class="arrow"></span>
            </a>
          </li>
          <li *ngIf="user && user?.isFree==1 && user?.subscription_past_due && (user?.user_type != 5 && user?.team_access_level != 1)" class="menu-dropdown classic-menu-dropdown" [ngClass]="{active: isActiveRoute('team')}">
            <a [routerLink]="['/team']"> Team
              <span class="arrow"></span>
            </a>
          </li>
          <li class="menu-dropdown classic-menu-dropdown" [ngClass]="{active: isActiveRoute('profile')}">
            <a [routerLink]="['/profile/settings']"> My Profile
              <span class="arrow"></span>
            </a>
          </li>
          <li *ngIf="user && (user?.user_type != 5 )" class="menu-dropdown classic-menu-dropdown" [ngClass]="{active: isActiveRoute('file/library')}">
            <a [routerLink]="['/file/library']"> Company File Library
              <span class="arrow"></span>
            </a>
          </li>
          <li class="menu-dropdown classic-menu-dropdown" [ngClass]="{active: isActiveRoute('support')}">
            <a [routerLink]="['/support']"> Technical Support
              <span class="arrow"></span>
            </a>
          </li>
          <!-- <li class="menu-dropdown classic-menu-dropdown" *ngIf="user && user?.team_access_level != 1 && user?.user_type != 5 && !january22User" [ngClass]="{active: isActiveRoute('desktop')}">
            <a [routerLink]="['/desktop']"> Desktop Application
              <span class="arrow"></span>
            </a>
          </li> -->
        </ul>
      </div>
      <!-- END MEGA MENU -->
    </div>
  </div>
  <!-- END HEADER MENU -->
</div>

<router-outlet></router-outlet>

<footer class="page-footer">
  <div class="container">
    <!-- <div class="col s12">TrialLine by <a href="http://www.lawfather.net/" target="_blank">Law Father</a></div> -->
<!--    <div class="col s12">Phone: <a href="tel:800-325-7715">800.325.7715</a></div>-->
    <div class="col s12">Email: <a href="mailto:info@trialline.net">info@trialline.net</a></div>
    <!-- and email form should also CC <a href="mailto:travis@lawfather.com">travis@lawfather.com</a> -->
  </div>
</footer>
<div (click)="scrollToTop()" class="scroll-to-top" style="display: block;">
  <i class="fa fa-arrow-up"></i>
</div>

<div id="from-old-first-time-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" style="width: 95%;">
    <div class="modal-content">
      <!--<img src="assets/squares.gif" class="img spinners center-block" alt="spinner" *ngIf="! user">-->
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h3 class="modal-title">YOUR 7-DAY FREE TRIAL IS NOW LIVE</h3>
      </div>
      <div class="modal-body">
        <p>Thank you for using TrialLine. We have just activated your FREE 7-day subscription. Your free trial is for the Month-to-Month subscription. The Month-to-Month subscription gives you all the features available in TrialLine.</p>
        <p>At the conclusion of your free trial, you will
          be asked to provide a credit card and activate your paid subscription. You can continue Month-to-Month or opt-in to a less expensive Annual subscription without any disruption to the work you have already created.</p>
        <p>Thank you for being a TrialLine client! We look forward to helping you and your team see your cases more clearly.</p>
      </div>
    </div>
  </div>
</div>
               <app-add-card [user]="user" (onAdded)="cardAdded($event)" [id_form]="1"></app-add-card>
<app-subscription *ngIf="user" [user]="user" (onSubscribed)="reloadRenew($event)"></app-subscription>
<div id="trial-end-banner" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" style="width: 95%;">
    <div class="modal-content">
      <!--<img src="assets/squares.gif" class="img spinners center-block" alt="spinner" *ngIf="! user">-->
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h3 class="modal-title">YOUR FREE TRIAL HAS ENDED </h3>
      </div>
      <div class="modal-body">
        <p>Dear TrialLine Customer,</p>
        <p>Your 7-day free trial has ended.</p>
        <p>If you experience any problems with your new version of TrialLine, we will address them right away. Just fill out a support ticket and we will respond immediately. We can even provide a live demo and tutorial.</p>
        <!-- <p>Your previous TrialLine subscription has been cancelled. Reactivating your account is easy. Got to My Profile → Subscription → Choose a New Plan → Enter Your Payment Details, or <a [routerLink]="['/profile/subscription']"><span data-dismiss="modal">Click Here</span></a>. </p> -->
      </div>
    </div>
  </div>
</div>
<!-- My changes -->
<div id="subscription-trial-end-banner" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" style="width: 95%;">
    <div class="modal-content">
      <!--<img src="assets/squares.gif" class="img spinners center-block" alt="spinner" *ngIf="! user">-->
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h3 class="modal-title">YOUR ACCOUNT IS INACTIVE</h3>
      </div>
      <div class="modal-body">
        <p>Dear TrialLine Customer,</p>
         <p>Your previous TrialLine subscription has been cancelled. Reactivating your account is easy. Got to My Profile → Subscription → Choose a New Plan → Enter Your Payment Details, or <a [routerLink]="['/profile/subscription']"><span data-dismiss="modal">Click Here</span></a>. </p>
      </div>
    </div>
  </div>
</div>

<div id="browser-modal" class="modal fade bs-modal-lg in" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header ">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">We noticed you are not using Google Chrome.</h4>
      </div>
      <div class="modal-body modal-bredirectUserToAdminPanelody">
        <p>We noticed you are not using Google Chrome. There are a lot of Internet browsers out there, but TrialLine is
          optimized for Google Chrome. You may run into tech problems without it. Get the most out of your TrialLine
          program and <a href="https://www.google.com/intl/en/chrome/"  target="_blank">download Google Chrome here</a>,
          or if you already have Chrome, close this browser and login to
          TrialLine using Chrome.</p>
      </div>
      <div class="modal-footer">
        <button data-dismiss="modal" class="btn btn-primary">
          Got It
        </button>
        <button [disabled]="processing" (click)="stopRemindingMe()" data-dismiss="modal" class="btn btn-default">
          Stop Reminding Me
        </button>
      </div>
    </div>
  </div>
</div>

