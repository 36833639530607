import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';

import {AuthResourceService} from "../shared/auth-resoruce.service";

@Component({
  selector: 'app-auth-confirm',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit
{
  constructor(
    protected authResourceService: AuthResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {

      let token = params['token'];

      if (! token) {


      } else {

        this.authResourceService.confirm(token).subscribe(
          (response: any) => this.handleSuccess(response),
          (error: any) => this.handleError(error)
        );
      }
    });
  }

  handleSuccess(response: Response)
  {
    this.toastr.success('Successfully confirmed');
    this.router.navigateByUrl('/login')
  }

  handleError(error: Response)
  {
    this.toastr.error('Token does not exists or has expired');
    this.router.navigateByUrl('/register')
  }
}
