import {NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {WelcomeComponent} from "./welcome/welcome.component";
import {SampleTriallinesComponent} from "./sample-triallines/sample-triallines.component";
import {LoginFormComponent} from "./forms/login/login-form.component";
import {RegisterFormComponent} from "./forms/register/register-form.component";
import {ValidatorModule} from "../form-validator/validator.module";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {TestComponent} from "./test/test.component";
import {SharedComponentsModule} from "../shared-components/shared-components.module";
import {TestResolver} from "./test/test.resolver";
import {RecaptchaModule} from "ng-recaptcha";
import {ForbiddenComponent} from "./forbidden/forbidden.component";
import {NgxPaginationModule} from "ngx-pagination";
import {TermsOfUseComponent} from './terms-of-use/terms-of-use.component';
import {PopupFormComponent} from "./forms/popap_form/popup-form.component";
import {Present2Module} from "../present2/present.module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ValidatorModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    Present2Module,
    RecaptchaModule,
    NgxPaginationModule
  ],
  declarations: [
    PageNotFoundComponent,
    ForbiddenComponent,
    PrivacyPolicyComponent,
    WelcomeComponent,
    SampleTriallinesComponent,
    LoginFormComponent,
    RegisterFormComponent,
    TestComponent,
    TermsOfUseComponent,
    PopupFormComponent
  ],
  providers: [
    TestResolver
  ]
})
export class IndexModule {}
